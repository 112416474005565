import './App.css';
import Home from './Components/Home';

function App() {
  return (
    <Home />
  );
}

export default App;
